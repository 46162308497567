export const GET_STUDENTS_REQUEST = '@students/GET_STUDENTS_REQUEST';
export const GET_STUDENTS_SUCCESS = '@students/GET_STUDENTS_SUCCESS';
export const GET_STUDENTS_FAILURE = '@students/GET_STUDENTS_FAILURE';

export const POST_STUDENT_REQUEST = '@students/POST_STUDENT_REQUEST';
export const POST_STUDENT_SUCCESS = '@students/POST_STUDENT_SUCCESS';
export const POST_STUDENT_FAILURE = '@students/POST_STUDENT_FAILURE';

export const PUT_STUDENT_REQUEST = '@students/PUT_STUDENT_REQUEST';

export const DELETE_STUDENT_REQUEST = '@students/DELETE_STUDENT_REQUEST';
export const DELETE_STUDENT_SUCCESS = '@students/DELETE_STUDENT_SUCCESS';
export const DELETE_STUDENT_FAILURE = '@students/DELETE_STUDENT_FAILURE';

export const GET_REGISTRATIONS_REQUEST =
    '@registrations/GET_REGISTRATIONS_REQUEST';
export const GET_REGISTRATIONS_SUCCESS =
    '@registrations/GET_REGISTRATIONS_SUCCESS';
export const GET_REGISTRATIONS_FAILURE =
    '@registrations/GET_REGISTRATIONS_FAILURE';

export const DELETE_REGISTRATION_REQUEST =
    '@registrations/DELETE_REGISTRATION_REQUEST';
export const DELETE_REGISTRATION_SUCCESS =
    '@registrations/DELETE_REGISTRATION_SUCCESS';
export const DELETE_REGISTRATION_FAILURE =
    '@registrations/DELETE_REGISTRATION_FAILURE';

export const POST_REGISTRATION_REQUEST =
    '@registrations/POST_REGISTRATION_REQUEST';
export const POST_REGISTRATION_SUCCESS =
    '@registrations/POST_REGISTRATION_SUCCESS';
export const POST_REGISTRATION_FAILURE =
    '@registrations/POST_REGISTRATION_FAILURE';

export const PUT_REGISTRATION_REQUEST =
    '@registrations/PUT_REGISTRATION_REQUEST';
export const PUT_REGISTRATION_SUCCESS =
    '@registrations/PUT_REGISTRATION_SUCCESS';
export const PUT_REGISTRATION_FAILURE =
    '@registrations/PUT_REGISTRATION_FAILURE';
